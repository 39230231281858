<template>
  <div class="article" :class="{ active: fullScreen }">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <span>조회시간: {{ str_time }}</span>

        <button class="btn_sub1" @click="fullScreen = !fullScreen">
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
        <h5 class="title">냉장고 현황</h5>
      </div>
      <div class="refrige_wrap" v-for="item in refrigLog" :key="item.id">
        <h5>
          {{ item[0].plc_sensor_name.substr(0, 12) }}
        </h5>
        <div class="refrige_image"></div>
        <ul>
          <li>
            현재온도 :
            {{
              item.find(x => x.plc_sensor_detail_type_name.includes('온도'))
                .value
            }}
            ℃
          </li>
          <li>
            {{
              `${
                item.find(x => x.plc_sensor_detail_type_name.includes('습도'))
                  ? '현재습도 : ' +
                    item.find(x =>
                      x.plc_sensor_detail_type_name.includes('습도'),
                    ).value +
                    '%'
                  : ''
              }`
            }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import { yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';
export default {
  mixins: [FETCH_MIXIN],
  data() {
    return {
      fullScreen: false,
      str_time: yyyy년MM월dd일HH시mm분ss초(new Date()),
      timerCount: 10,
      timerMax: 10,
      interval: null,
      timeInterval: null,
      currentTimeInterval: null,
    };
  },
  computed: {
    ...mapGetters({
      plc_sensor_logs: 'getPlcSensorLog',
      plc_temprefrige_logs: 'getTemperaturerefrigeditySensorLog',
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
    }),
    refrigLog() {
      if (
        this.plc_sensor_logs != undefined &&
        this.plc_sensor_logs.length > 0
      ) {
        let clone = this.lodash
          .clonedeep(this.plc_sensor_logs)
          .filter(
            x =>
              x.plc_sensor_detail_type_id == 1 ||
              x.plc_sensor_detail_type_id == 2 ||
              x.plc_sensor_name.includes('반죽성형실 급속냉동실 온도'),
          )
          .sort((a, b) => a.plc_sensor_id - b.plc_sensor_id);
        let arr = [];
        clone.forEach((el, index) => {
          el.plc_location_id;
          if (index % 2 == 0) {
            arr.push(clone.slice(index, index + 2));
          }
        });
        return arr;
      } else {
        return [];
      }
    },
  },
  destroyed() {
    console.log('destroyed');
    clearInterval(this.interval);
    clearInterval(this.timeInterval);
    clearInterval(this.currentTimeInterval);
  },
  async created() {
    this.timerCount = this.timerMax;
    await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      if (this.tabIndex == 2) {
        await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');
      }
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 1000);
    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);
    this.currentTimeInterval = setInterval(() => {
      this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
    }, 1000);
  },
};
</script>

<style lang="scss" scoped></style>
